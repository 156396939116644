<!--
 * @Description: 
 * @version: 
 * @Author: chentianxiang
 * @Date: 2023-08-24 16:39:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-08-24 18:18:25
-->
<template>
  <div>
    <div class="tab" @click="tabChange">
      <div data-value="0" :class="currentTab == 0 ? 'tab-select' : ''">开票信息</div>
      <div data-value="1" :class="currentTab == 1 ? 'tab-select' : ''">其他信息</div>
    </div>
    <div v-if="currentTab == 0">
      <el-row class="top">
        <el-col :span="6" class="top-item">
          <img src="../../../assets/images/invoice/money.png" class="icon" />
          <span>开票总金额(元)</span>
          <span class="f-26">5</span>
        </el-col>
        <el-col :span="6" class="top-item">
          <img src="../../../assets/images/invoice/order.png" class="icon" />
          <span>订单个数(个)</span>
          <span class="f-26">5</span>
        </el-col>
        <el-col :span="6" class="top-item">
          <img src="../../../assets/images/invoice/goods.png" class="icon" />
          <span>品类数量(个)</span>
          <span class="f-26">5</span>
        </el-col>
        <el-col :span="6" class="top-item">
          <img src="../../../assets/images/invoice/invoice.png" class="icon" />
          <span>开票数量(个)</span>
          <span class="f-26">5</span>
        </el-col>
      </el-row>
      <div class="sperator"></div>
      <div class="product-box">
        <div class="info-title">商品列表</div>
        <el-form label-width="80px" inline>
          <el-form-item label="批号">
            <el-input />
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input />
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-input />
          </el-form-item>
          <el-form-item style="text-align: right;">
            <el-button type="success" size="small">搜索</el-button>
            <el-button size="small">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData" border height="450" :span-method="dataSpanMethod">
          <el-table-column type="index" label="序号" width="80" align="center" />
          <el-table-column prop="batchNo" label="批号" align="center" />
          <el-table-column prop="productName" label="商品名称" align="center" :show-overflow-tooltip="true" />
          <el-table-column prop="manufacturer" label="生产厂家" align="center" :show-overflow-tooltip="true" />
          <el-table-column prop="spec" label="件装量" align="center" />
          <el-table-column prop="gg" label="规格" align="center" />
          <el-table-column prop="orderNo" label="商城订单编号" align="center" />
          <el-table-column prop="no" label="关联出库单" align="center" />
          <el-table-column prop="type" label="单据类型" align="center" />
          <el-table-column prop="productNum" label="涉及商品数" align="center" />
          <el-table-column prop="money" label="涉及金额" align="center" />
          <el-table-column prop="invoiceNum" label="开票数量" align="center" width="130" />
        </el-table>
        <el-pagination
          class="page-box"
          background
          layout="prev, pager, next"
          :total="100">
        </el-pagination>
      </div>
    </div>

    <div v-if="currentTab == 1">
      <el-row class="info-tab">
        <el-col :span="10">
          <div class="info-title">客户信息</div>
          <div class="customer-box">
            <div><span>客户：</span>江西仁和中方医药股份有限公司</div>
            <div><span>发票类型：</span>增值税发票</div>
          </div>
        </el-col>
        <el-col :span="14" style="padding-left: 150px;">
          <div class="info-title">单据信息</div>
          <div class="customer-box form-box">
            <div><span>抬头类型：</span>事业单位</div>
            <div><span>发票抬头：</span>成都大药房</div>
            <div><span>开户银行名称：</span>成都工商银行天府支行</div>
            <div><span>基本开户账号：</span>2345678912345616</div>
            <div><span>注册场所地址：</span>成都市青羊区西御街111号</div>
            <div><span>注册固定电话：</span>028-124577</div>
            <div><span>统一社会信用代码：</span>12273683764898075P</div>
          </div>
        </el-col>
      </el-row>
      <div class="sperator"></div>
      <div class="approve-box">
        <div class="info-title">审批记录</div>
        <div class="approve-list">
          <div class="item" v-for="(item, index) in approveList" :key="index">
            <div class="item-title">
              <span>{{ item.name }}</span>
              <span class="status-box orange-box">申请人</span>
            </div>
            <div class="item-img">
              <img src="../../../assets/images/invoice/apply-person.png" />
              <div v-show="index < approveList.length -1" class="item-line" :class="item.time ? '' : 'item-line-empty'"></div>
            </div>
            <div class="item-time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTab: 1,
      tableData:[
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
      ],
      mergeObj: {},
      mergeArr: ['batchNo', 'productName', 'manufacturer', 'packSpec', 'spec',],
      approveList:[
        {name: '林波', time: '2022-04-24 17:39:00'},
        {name: '林波', time: ''},
        {name: '林波', time: '2022-04-24 17:39:00'}
      ]
    }
  },
  methods: {
    tabChange(e) {
      if (e.target.dataset.value) {
        this.currentTab = e.target.dataset.value;
      }
    },
    // 获取合并数据
    getSpanArr(data) {
      this.mergeArr.forEach((key, index1) => {
        let count = 0; // 记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一行的合并信息
        data.forEach((item, index) => {
          // index === 0表示数据为第一行，直接push一个1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等，如果相等，在count记录的位置其值加1 表示当前行需要合并 并push一个0作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行与上一行其值不相等
              count = index;
              this.mergeObj[key].push(1);
            }
          }
        });
      });
    },
    dataSpanMethod({row, column, rowIndex, columnIndex}) {
      if (this.mergeArr.indexOf(column.property) !== -1) {
        if (this.mergeObj[column.property][rowIndex]) {
          return [this.mergeObj[column.property][rowIndex],1 ]
        } else {
          return [0, 0]
        }
      }
    },
  },

  mounted(){
    this.getSpanArr(this.tableData)
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 54px;
  background: #F7F8FA;
  font-size: 16px;
  color: #646464;
  display: flex;
  div {
    width: 160px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
  }
  .tab-select {
    background: #ffffff;
    color: #26C487;
    border-top: 4px solid #26C487;
  }
}

.sperator {
  width: 100%;
  height: 20px;
  background: #F3F5F7;
}
.top {
  height: 100px;
  padding-top: 35px;
  .top-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-right: 1px solid #E9ECF0;
    padding-left: 60px;
    color: #333333;
    &:last-child {
      border: none;
    }
    img {
      width: 32px;
      height: 32px;
      margin-right: 7px;
    }
    .f-26 {
      font-size: 26px;
      font-family: 'bold';
      margin-left: 10px;
    }
  }
}

.info-title {
  font-size: 18px;
  font-family: 'medium';
  color: #333333;
  height: 20px;
  border-left: 5px solid #26C487;
  padding-left: 12px;
}
.product-box {
  padding: 30px 20px;
  .el-form {
    margin: 30px 0 20px;
  }
  .el-form-item {
    width: 24%;
  }
  .el-input {
    width: 220px;
  }
  :deep(.el-input__inner) {
    height: 32px;
  }
  :deep(.el-form-item__label) {
    text-align: left;
    color: #333333;
  }
}

.el-table {
  :deep(th.el-table__cell) {
    background: #F7F8FA;
  }
  :deep(.el-input-number--mini) {
    width: 100px;
    .el-input-number__increase, .el-input-number__decrease {
      height: 30px;
    }
  }
}

.page-box {
  margin: 18px 0;
  text-align: right;
}

.info-tab {
  width: 100%;
  height: 290px;
  padding: 30px;
  .info-title {
    height: 16px;
    font-size: 14px;
  }
  .customer-box {
    height: 192px;
    border-right: 1px solid #F5F5F5;
    div {
      margin-top: 18px;
    }
    span {
      display: inline-block;
      width: 100px;
      text-align: right;
      color: #9E9FAB;
    }
  }
  .form-box {
    border: none;
    span {
      width: 160px;
    }
  }
}

.approve-box {
  height: 248px;
  padding: 30px;
  .info-title {
    height: 16px;
    font-size: 14px;
  }
}

.approve-list {
  margin-top: 40px;
  padding: 0 40px;
  display: flex;
  .item {
    width: 200px;
    .item-title {
      margin-bottom: 20px;
      padding: 6px 0 0 20px;
    }
    img {
      width: 36px;
      height: 36px;
    }
    .item-time {
      width: 80px;
      font-size: 12px;
      color: #707070;
      line-height: 20px;
      text-align: center;
    }
    .item-img {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 20px;
      .item-line {
        width: 140px;
        height: 0px;
        border: 2px dashed #26C487;
        margin-left: 14px;
      }
      .item-line-empty {
        border-color: #CDCDCD;
      }
    }
  }
  .status-box {
    display: inline-block;
    width: 50px;
    height: 24px;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    margin-left: 20px;
  }
  .orange-box {
    background: #FFF1DD;
    color: #FFA936;
  }
}
</style>