<!--
 * @Description: 
 * @version: 
 * @Author: chentianxiang
 * @Date: 2023-08-24 11:12:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-08-24 16:10:33
-->
<template>
  <div>
    <el-row class="top">
      <el-col :span="6" class="top-item">
        <img src="../../../assets/images/invoice/money.png" class="icon" />
        <span>开票总金额(元)</span>
        <span class="f-26">5</span>
      </el-col>
      <el-col :span="6" class="top-item">
        <img src="../../../assets/images/invoice/order.png" class="icon" />
        <span>订单个数(个)</span>
        <span class="f-26">5</span>
      </el-col>
      <el-col :span="6" class="top-item">
        <img src="../../../assets/images/invoice/goods.png" class="icon" />
        <span>品类数量(个)</span>
        <span class="f-26">5</span>
      </el-col>
      <el-col :span="6" class="top-item">
        <img src="../../../assets/images/invoice/invoice.png" class="icon" />
        <span>开票数量(个)</span>
        <span class="f-26">5</span>
      </el-col>
    </el-row>
    <div class="sperator"></div>
    <div class="info-box">
      <div class="info-title">开票信息</div>
      <el-row class="info-radio">
        <el-col :span="12">
          <span>发票类型：</span>
          <el-radio-group>
            <el-radio :label="3">电子增值税发票</el-radio>
            <el-radio :label="6">电子普通发票</el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="12"><span>发票抬头：</span>成都平原大生源药房连锁有限公司市临镇西街药店</el-col>
      </el-row>
    </div>
    <div class="sperator"></div>
    <div class="product-box">
      <div class="info-title">商品列表</div>
      <el-form label-width="80px" inline>
        <el-form-item label="批号">
          <el-input />
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input />
        </el-form-item>
        <el-form-item label="生产厂家">
          <el-input />
        </el-form-item>
        <el-form-item style="text-align: right;">
          <el-button type="success" size="small">搜索</el-button>
          <el-button size="small">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border height="450" :span-method="dataSpanMethod">
        <el-table-column type="index" label="序号" width="80" align="center" />
        <el-table-column prop="batchNo" label="批号" align="center" />
        <el-table-column prop="productName" label="商品名称" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="manufacturer" label="生产厂家" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="spec" label="件装量" align="center" />
        <el-table-column prop="gg" label="规格" align="center" />
        <el-table-column prop="orderNo" label="商城订单编号" align="center" />
        <el-table-column prop="no" label="关联出库单" align="center" />
        <el-table-column prop="type" label="单据类型" align="center" />
        <el-table-column prop="productNum" label="涉及商品数" align="center" />
        <el-table-column prop="money" label="涉及金额" align="center" />
        <el-table-column label="开票数量" align="center" width="130">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.invoiceNum" :min="1" label="描述文字" size="mini" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page-box"
        background
        layout="prev, pager, next"
        :total="100">
      </el-pagination>
      <div class="bottom">
        <el-button @click="goBack">取消</el-button>
        <el-button type="success">提交申请</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData:[
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
        {batchNo: '436456', productName: '云南白药药膏', manufacturer: '云南白药集团股份有限公司', packSpec: '100袋/件', spec: '100g/袋', orderNo: '24645757', no: '34757', type: '销售出库单', productNum: '10', money: '12', invoiceNum: '1'},
      ],
      mergeObj: {},
      mergeArr: ['batchNo', 'productName', 'manufacturer', 'packSpec', 'spec',]
    }
  },
  methods: {
    // 获取合并数据
    getSpanArr(data) {
      this.mergeArr.forEach((key, index1) => {
        let count = 0; // 记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一行的合并信息
        data.forEach((item, index) => {
          // index === 0表示数据为第一行，直接push一个1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等，如果相等，在count记录的位置其值加1 表示当前行需要合并 并push一个0作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行与上一行其值不相等
              count = index;
              this.mergeObj[key].push(1);
            }
          }
        });
      });
    },
    dataSpanMethod({row, column, rowIndex, columnIndex}) {
      if (this.mergeArr.indexOf(column.property) !== -1) {
        if (this.mergeObj[column.property][rowIndex]) {
          return [this.mergeObj[column.property][rowIndex],1 ]
        } else {
          return [0, 0]
        }
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },

  mounted(){
    this.getSpanArr(this.tableData)
  }
}
</script>

<style lang="less" scoped>
.sperator {
  width: 100%;
  height: 20px;
  background: #F3F5F7;
}
.top {
  height: 100px;
  padding-top: 35px;
  .top-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-right: 1px solid #E9ECF0;
    padding-left: 60px;
    color: #333333;
    &:last-child {
      border: none;
    }
    img {
      width: 32px;
      height: 32px;
      margin-right: 7px;
    }
    .f-26 {
      font-size: 26px;
      font-family: 'bold';
      margin-left: 10px;
    }
  }
}
.info-box {
  height: 128px;
  padding: 30px 20px;
  .info-radio {
    margin-top: 30px;
    padding-left: 17px;
    span {
      color: #595A6D;
    }
    .el-radio {
      color: #202D40;
    }
  }
}
.info-title {
  font-size: 18px;
  font-family: 'medium';
  color: #333333;
  height: 20px;
  border-left: 5px solid #26C487;
  padding-left: 12px;
}
.product-box {
  padding: 30px 20px;
  .el-form {
    margin: 30px 0 20px;
  }
  .el-form-item {
    width: 24%;
  }
  .el-input {
    width: 220px;
  }
  :deep(.el-input__inner) {
    height: 32px;
  }
  :deep(.el-form-item__label) {
    text-align: left;
    color: #333333;
  }
}

.el-table {
  :deep(th.el-table__cell) {
    background: #F7F8FA;
  }
  :deep(.el-input-number--mini) {
    width: 100px;
    .el-input-number__increase, .el-input-number__decrease {
      height: 30px;
    }
  }
}

.page-box {
  margin: 18px 0;
  text-align: right;
}

.bottom {
  height: 70px;
  border-top: 1px solid #E9ECF0;
  padding:  25px 20px 0 0;
  text-align: right;
}
</style>